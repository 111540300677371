.welcome {
  &__content {
    color: #fff;
    position: relative;

    .close {
      transition: transform 0.4s;
      position: absolute;
      right: 3rem;
      top: 3rem;
      width: 2.2rem;
      cursor: pointer;
    }
  }

  &__illustration{
    width: 30rem;
    margin-top: -295px;
  }

  &__logo {
    position: absolute;
    padding-top: 12rem;
    right: 8rem;
    width: 18rem;
  }

  &__textSection {
    margin-top: 15rem;
    margin-left: 44rem;
  }

  &__head,
  &__message {
    font-weight: bold;
    max-width: 50rem;
    position: relative;

    > img {
      position: absolute;
      top: -10.5rem;
      left: 12rem;
      width: 16rem;
    }
  }

  &__subMsg {
    max-width: 32rem;
    margin-top: 3rem;
    
    letter-spacing: 0.1rem;
  }

  &__action {
    margin-top: 8rem;
    display: flex;
    justify-content: center;
    margin-right: 18rem;
  }

  &__button {
    background: black;
    padding: 1.4rem 6rem;
    
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .MuiPaper-root {
    background-image: url('../../../../assets/bg_welcome_nw.png');
    min-width: 128rem;
    min-height: 72rem;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
  }
}
